import React from 'react';
import './App.css';
import axios from 'axios';
import moment from 'moment';

export default class extends React.Component {
    state = {
        error: false,
        quote: '',
        image: '',
        quotesToPost: []
    };

    refreshQuote = () => {
        axios.get('/api/random-quote/').then(({ data }) => {
            if ('error' in data) {
                this.setState({ error: data.error });
            } else {
                this.setState({ quote: data[0].quote, image: '' });
            }
        });
    };

    refreshQuotesList = () => {
        axios.get('/api/quotes-to-post').then(({ data }) => {
            if ('error' in data) {
                this.setState({ error: data.error });
            } else {
                this.setState({ quotesToPost: data });
            }
        });
    };

    addNewQuote = () => {
        if (this.state.quote !== '') {
            axios.post('/api/new-quote', {
                quote: this.state.quote
            }).then(() => {
                this.refreshQuote();
                this.refreshQuotesList();
            })
        }
    };

    deleteQuote = quoteId => () => {
        axios.post('/api/delete-quote', {
            quoteId: quoteId
        }).then(() => {
            this.refreshQuotesList();
        })
    };

    previewImage = () => {
        if (this.state.quote !== '') {
            axios.post('/api/get-image', {
                quote: this.state.quote
            }).then(({ data }) => {
                this.setState({ image: data });
            });
        }
    };

    changeQuote = event => {
        this.setState({ quote: event.target.value });
    };

    componentDidMount() {
        this.refreshQuote();
        this.refreshQuotesList();
    }

    render() {
        return (
            <div className="container">
                <div className="d-flex justify-content-center mt-3 mb-3">
                    <h1>Quote generator</h1>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <textarea className="form-control mr-3" rows="4" onChange={this.changeQuote} value={this.state.quote}/>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={this.refreshQuote}>⟳</button><br/>
                        <button type="button" className="btn btn-info mt-2" onClick={this.previewImage}>Image</button><br/>
                        <button type="button" className="btn btn-success mt-2" onClick={this.addNewQuote}>Add</button>
                    </div>
                </div>
                { this.state.image !== '' ? (
                    <div className="mt-4 mb-4">
                        <h2 className="text-center mb-3">Image preview</h2>
                        <img className="quotePreview" src={'data:image/png;base64, ' + this.state.image}/>
                    </div>
                ):null }
                <div className="mt-4 mb-4">
                    <h2 className="text-center">Quotes</h2>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Quote</th>
                            <th scope="col">Image</th>
                            <th scope="col">Publication&nbsp;date</th>
                            <th scope="col">Remove</th>
                        </tr>
                        </thead>
                        <tbody>
                            { this.state.quotesToPost.map((item, index) => {
                                let imageUrlParts = item.imageUrl.split('/');
                                let imageName = imageUrlParts[imageUrlParts.length - 1];
                                return (
                                    <tr key={'quote_to_post_' + item.id}>
                                        <td>{ item.quote !== '' ? item.quote:'-' }</td>
                                        <td><a href={ item.imageUrl } target="_blank">{ imageName }</a></td>
                                        <td>{ moment(item.publicationDate).format('DD.MM.YYYY') }</td>
                                        <td>
                                            <button type="button" className="btn btn-danger"
                                                    onClick={this.deleteQuote(item.id)}>x</button>
                                        </td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
